.intro-container{
    padding: 10px;
    
}

@media only screen and (min-width: 768px) {
    .rsm-svg{
        height: 500px;
        width: auto;
    }
    .intro-container{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
}