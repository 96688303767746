.goods-container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 10%;
    grid-row-gap: 30px;
   
}
.card{
    margin-top: 10px;
    box-shadow: 0px 10px 17px 0px rgba(0,0,0,0.66);
    border: 1px solid rgba(119, 118, 118, 0.267);

}
.card1{
    border: 1px solid rgba(119, 118, 118, 0.267);
}
.card1:hover{
    // transform: scaleY(1.1);
    // transition: .5s;
    -webkit-box-shadow: 0px 10px 17px 0px rgba(0,0,0,0.66); 
box-shadow: 0px 10px 17px 0px rgba(0,0,0,0.66);
}
.goods-container1{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 10%;
    grid-row-gap: 30px;
}