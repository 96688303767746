.App {
  background-color: rgba(221, 217, 217, 0.596);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.app-wrap{
  background-color: white;
  width: 92%;
  position: relative;
  left: 4%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
 margin-top: 8px;
}
