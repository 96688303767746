.footer{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 10%;
    background-color: #1976d2;
    padding: 30px;
    a{
        text-decoration: none;
        color: black;
    }
}


@media only screen and (max-width: 450px) {
    .footer{
        display: flex;
        flex-direction: column;
        background-color: #1976d2;
        padding: 30px;

        a{
            text-decoration: none;
            color: black;
        }
    }
}