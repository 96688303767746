.aTag{
    text-decoration: none;
    font-weight: 500;
    color: black;
}
.aTag:hover{
    background-color: black;
    color: white;
}
.nav-wrap{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
   padding-left: 10%;

    .aTagDesktop{
        font-size: 25px;
        color: white;
        text-decoration: none;
        font-weight: 600;
    }
    .aTagDesktop:hover{
        background-color: bisque;
        color:black
    }
}