.contact-container{
    padding: 10px;
    .contactName{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .contactPhone{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;

    }
    .contactOther{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;

    }
}